.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* 0.7 for 70% opacity black */
    z-index: 999; /* Ensure the overlay is on top of other elements */
    display: none; /* Hide the overlay initially */
}
.loader-container{
    width: 100%;
    height: 100%;
    display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

}
.mb-2{
  margin-bottom: 20px;
}
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db; /* Spinner color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Rotate animation */
}

/* Spinner animation keyframes */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}